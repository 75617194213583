import React, { useState } from "react"

import Arrow from "../../assets/svg/cta_arrow.svg"

const FormCollabEn = ({ title, fields, title2, positions, title3, resume, description, website, cover }) => {
  const
    half = Math.ceil(positions.length / 2),
    firstHalf = positions.slice(0, half),
    secondHalf = positions.slice(-half);

  const [currentPosition, setCurrentPosition] = useState(0);

  return (
    <form className="grid-16" name='Latente-Collaborators-En' method='post'
      action="/en/success" data-netlify='true' data-netlify-honeypot='bot-field' autoComplete='off'>
      <input type='hidden' name='bot-field'/>
      <input type='hidden' name='form-name' value='Latente-Collaborators-En'/>

      <h3 data-sal='fade' data-sal-duration='750' data-sal-easing='ease' className="M16-regular form-title">{title}</h3>
      {fields?.map(field => (
        <fieldset data-sal='fade' data-sal-duration='750' data-sal-easing='ease' className="field-container" key={field._key}>
          <label htmlFor={field.name}>{field.label}</label>
          <input className="M24-light" type={field.type} name={field.name} placeholder={field.placeholder} required={field.required}/>
        </fieldset>
      ))}

      <h3 data-sal='fade' data-sal-duration='750' data-sal-easing='ease' className="M16-regular form-title inst">{title2}</h3>

      <div data-sal='fade' data-sal-duration='750' data-sal-easing='ease' className="positions-column-one">
        {firstHalf?.map((position, i) => {
          return (
            <fieldset key={position._key}>
              <input type="checkbox" name={position.name.replace(' ', '-')} value={position.name} checked={i === currentPosition} onChange={() => setCurrentPosition(i)} />
              <label className="M16-regular" htmlFor={position.name.replace(' ', '-')}>{position.name}</label>
            </fieldset>
          )
        })}
      </div>

      <div data-sal='fade' data-sal-duration='750' data-sal-easing='ease' className="positions-column-two">
        {secondHalf?.map((position, i)=> {
          return (
            <fieldset key={position._key}>
              <input type="checkbox" name={position.name.replace(' ', '-')} value={position.name} checked={(i + firstHalf.length) === currentPosition} onChange={() => setCurrentPosition(i + firstHalf.length)}/>
              <label className="M16-regular" htmlFor={position.name.replace(' ', '-')}>{position.name}</label>
            </fieldset>
          )
        })}
      </div>

      <h3 data-sal='fade' data-sal-duration='750' data-sal-easing='ease' className="M16-regular form-title three">{title3}</h3>

      <fieldset data-sal='fade' data-sal-duration='750' data-sal-easing='ease' className="field-container resume">
        <label htmlFor="resume">{resume}</label>
        <p className="M16-regular res-description">{description}</p>
        <input type="url" name="resume" placeholder="Url"/>
      </fieldset>

      <fieldset data-sal='fade' data-sal-duration='750' data-sal-easing='ease' className="field-container website">
        <label className="M16-regular" htmlFor="website">{website.label}</label>
        <input type={website.type} name={website.name} placeholder={website.placeholder}/>
      </fieldset>

      <p data-sal='fade' data-sal-duration='750' data-sal-easing='ease' className="M24-light coverLetter-title">{cover}</p>
      <p data-sal='fade' data-sal-duration='750' data-sal-easing='ease' className="M16-regular position-description">{positions[currentPosition].description}</p>

      <textarea data-sal='fade' data-sal-duration='750' data-sal-easing='ease' className="M24-light" name="cover-letter" placeholder="Hello,"></textarea>

      <button data-sal='fade' data-sal-duration='750' data-sal-easing='ease' type="submit" className="cta">Submit <Arrow className="arrow"/></button>
    </form>
  )
}

export default FormCollabEn
